<template>
  <v-container fluid>
    <v-dialog v-model="showAddSubDialog" max-width="600px">
      <v-card>
        <v-card-title>Link User as Sub Account</v-card-title>
        <v-card-text>
          <CustomerAutocomplete v-model="userToAdd"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showAddSubDialog=false">Cancel</v-btn>
          <v-btn @click="addSubAccount(userToAdd)" :loading="loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddManagedAccountDialog" max-width="600px">
      <v-card>
        <v-card-title>Add Existing Customer as Managed Account</v-card-title>
        <v-card-text>
          <CustomerAutocomplete v-model="userToAdd"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showAddManagedAccountDialog=false">Cancel</v-btn>
          <v-btn @click="updateManagedAccount(userToAdd.uid, true); showAddManagedAccountDialog=false"
                 :loading="loading">Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert :value="error">{{ error }}</v-alert>
    <v-card flat v-if="!loading && !error" class="">
      <v-card-actions>
        <v-btn text outlined @click="fetchCustomer(id)">Reload</v-btn>
        <v-spacer/>
        <v-btn v-if="canDeleteUser" color="red" text outlined @click="deleteCustomer">Delete</v-btn>
        <v-spacer/>
        <v-btn text outlined @click="saveCustomer">Save</v-btn>
        <v-spacer/>
        <CustomerAutocomplete
          class="text-right"
          :value="customer"
          v-on:input="$router.push({params:{id: $event.uid}})"
        />
      </v-card-actions>
      <v-card-title> {{ customer.first_name }} {{ customer.last_name }} ({{ customer.email }})
        <a target="_blank" :href="`https://app.fedfedfed.com/profile?uid=${id}`">
          <v-icon color="blue">mdi-incognito</v-icon>
        </a>
        <v-spacer/>
        <router-link v-if="customer.mainAccount" :to="{params:{id:customer.mainAccount}}">Main Account</router-link>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab key="detail">Customer Detail</v-tab>
          <v-tab key="address">Addresses</v-tab>
          <v-tab key="delivery">Delivery</v-tab>
          <v-tab key="notes">Notes</v-tab>
          <v-tab key="subaccounts">Subaccounts</v-tab>
          <v-tab key="managedAccounts">Managed Accounts</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="detail">
            <v-card flat>
              <v-card-title></v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="4" v-for="(field,i) of fields" v-bind:key="`${i}-${field}`">
                    <v-checkbox
                      dense
                      v-if="isCheckbox(field)"
                      :disabled="disabled[getProperty(field)]"
                      :label="getProperty(field)"
                      v-model="customer[getProperty(field)]"
                    />
                    <!--            {{ {v: getValue(field), t: getText(field), o: selectOptions(field)} }}-->
                    <v-select
                      v-if="isSelect(field)"
                      :disabled="disabled[getProperty(field)]"
                      :label="getProperty(field)"
                      :items="selectOptions(field)"
                      v-model="customer[getProperty(field)]"
                      :multiple="isMultiple(field)"
                      :clearable="isMultiple(field)"
                    />
                    <v-text-field
                      v-if="isCurrency(field)"
                      :disabled="disabled[getProperty(field)]"
                      :label="`${getProperty(field)} (in cents)`"
                      type="number"
                      v-model="customer[getProperty(field)]"
                    />
                    <v-text-field
                      v-if="isTextField(field)"
                      :disabled="disabled[getProperty(field)]"
                      :label="getProperty(field)"
                      v-model="customer[getProperty(field)]"
                    />
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-actions>
                <v-btn text outlined @click="changeEmailPrompt">Change Email Address</v-btn>
                <v-btn text outlined v-if="canEditPassword" @click="changePasswordPrompt">Change Password</v-btn>
                <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item key="address">
            <v-card flat>
              <v-tabs v-model="addressTab">
                <v-tab>Primary Address</v-tab>
                <v-tab v-for="(address,index) of addressList" v-bind:key="`${index}-address`">
                  {{ address }}
                </v-tab>
                <v-spacer/>
                <!--                {{ addresses }}-->
                <v-btn outlined @click="addNewAddress">Add Another Address</v-btn>
              </v-tabs>
              <v-spacer/>


              <v-card-text>
                <b>
                  Note: City is shown twice below. If you enter a city not in our standard rates, the dropdown will be
                  blank and the default shipping charge will be used ($9.99)
                </b>
                <v-row>
                  <v-col cols="3" v-for="(field,i) of addressFields" v-bind:key="`${i}-${field}`">
                    <template v-if="field!=='blank'">
                      <v-select
                        v-if="isSelect(field)"
                        :disabled="disabled[getProperty(field)]"
                        :label="getProperty(field)"
                        :items="selectOptions(field)"
                        v-model="address[getProperty(field)]"
                        :multiple="isMultiple(field)"
                      />
                      <v-checkbox
                        dense
                        v-if="isCheckbox(field)"
                        :disabled="disabled[getProperty(field)]"
                        :label="getProperty(field)"
                        v-model="address[getProperty(field)]"
                      />
                      <v-text-field v-if="isTextField(field)"
                                    :label="field"
                                    :disabled="disabled[field]"
                                    v-model="address[field]"
                      />
                      <v-text-field
                        v-if="isTime(field)"
                        :disabled="disabled[getProperty(field)]"
                        :label="`${getProperty(field)} (24 hour clock)`"
                        type="time"
                        v-model="address[getProperty(field)]"
                      />
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="delivery_instruction"
                      v-model="address['delivery_instruction']"
                    />
                  </v-col>
                </v-row>
                <v-alert
                  v-if="customer.plan==='flex' && !address.use_shipping"
                  type="warning" outlined
                >
                  Warning: if plan is 'flex' then 'use_shipping' must be checked!
                  <br/>
                  Also, make sure you "clear all" on the customer's order when changing to (or from) shipping to
                  local
                  delivery
                </v-alert>
                <v-alert
                  v-if="customer.plan!=='flex' && address.use_shipping"
                  type="warning" outlined
                >
                  Warning: if plan is not 'flex' then 'use_shipping' must be unchecked
                </v-alert>
              </v-card-text>

            </v-card>
          </v-tab-item>
          <v-tab-item key="delivery">
            <v-card-title>Delivery Address Overrides</v-card-title>
            <v-card-text>
              If an alternative delivery address is required for a particular delivery, it may be
              set here. If left blank, the customer's primary address will be used.
              <br/>
              <br/>
              Customer primary address is :
              {{ formatAddressAndDeliveryTimes(customer.address || {}) }}


              <template v-if="customer && customer.useAddress">
                {{ customer.useAddress.mon_tue_wed }}
                {{ customer.useAddress.thu_fri_sat }}
              </template>
            </v-card-text>
            <template v-if="customer.useAddress">
              <v-card-actions>
                <v-select
                  label="Mon/Tue/Wed Meals"
                  v-model="customer.useAddress.mon_tue_wed"
                  :items="addresses"
                  :item-text="a => `${formatAddressAndDeliveryTimes(a || customer.address)}`"
                  return-object
                  clearable
                />
                <v-spacer/>
                <v-select
                  label="Thu/Fri/Sat Meals"
                  v-model="customer.useAddress.thu_fri_sat"
                  :items="addresses"
                  :item-text="a => `${formatAddressAndDeliveryTimes(a || customer.address)}`"
                  return-object
                  clearable
                />
              </v-card-actions>
            </template>
          </v-tab-item>
          <v-tab-item key="notes">
            <v-card flat>
              <v-card-title>
                Notes
                <v-spacer/>
                <v-btn @click="addNote">Add Note</v-btn>
              </v-card-title>
              <v-card-text>
                <v-row v-for="(note,i) of customer.customer_notes" v-bind:key="`${i}-note`">
                  <v-col>
                    <v-combobox
                      label="Start"
                      v-model="note.start"
                      :items="upcomingDeliveryDates"
                      clearable
                      v-on:change="note.end = note.end >= note.start ? note.end : note.start"
                    />
                  </v-col>
                  <v-col>
                    <v-combobox
                      label="End"
                      v-model="note.end"
                      :items="upcomingDeliveryDates"
                      clearable
                      v-on:change="note.start = note.end >= note.start ? note.start : note.end"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Note"
                      v-model="note.text"
                    />
                  </v-col>
                  <v-col>
                    <v-btn icon @click="customer.customer_notes.splice(i,1)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="subaccounts">
            <v-card flat v-if="customer.mainAccount">
              <v-card-text>
                This account is a sub-account and you cannot create a subaccount of a subaccount!
                <br/>
                Go to the
                <router-link :to="{params:{id:customer.mainAccount}}">Main Account</router-link>
              </v-card-text>
            </v-card>
            <v-card flat v-if="!customer.mainAccount">
              <v-card-title>
                SubAccounts
                <v-spacer/>
                <v-btn @click="showAddSubDialog=true" text outlined v-if="canEditSubaccounts">
                  Link a sub-account
                  <v-icon color="green">mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="text-right" v-if="!customer.mainAccount">
                <v-row dense v-for="(sub,uid) of (customer.subAccounts||{})" v-bind:key="`${uid}-sub`">
                  <template v-if="!subAccounts[uid]">
                    <v-col>Customer not found</v-col>
                  </template>
                  <template v-if="subAccounts[uid]">
                    <router-link :to="{params: {id: uid}}">
                      {{ subAccounts[uid].first_name }} {{ subAccounts[uid].last_name }}
                    </router-link>
                    <v-col class="text-right">

                      ({{ subAccounts[uid].email }})
                    </v-col>
                  </template>
                  <v-col cols="1" class="text-right" v-if="canEditSubaccounts">
                    <v-btn icon @click="confirmUnlinkSub(uid)">
                      <v-icon color="red">mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="managedAccounts">
            <v-card flat v-if="!customer.is_account_manager">
              <v-card-text>
                This account is not an account manager.
              </v-card-text>
            </v-card>
            <v-card flat v-if="customer.is_account_manager">
              <v-card-title>
                Managed Accounts (read access and commission)
                <v-spacer/>
                <v-btn @click="showAddManagedAccountDialog=true" text outlined v-if="canEditCommission">
                  Add Existing Account
                  <v-icon color="green">mdi-plus</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="text-right">
                <v-row dense v-for="(managedAccount,uid) of (customer.accountsManaged||{})"
                       v-bind:key="`${uid}-managed`">
                  <template v-if="managedAccount.allowed">
                    <template v-if="!subAccounts[uid]">
                      <v-col>Customer not found</v-col>
                    </template>
                    <template v-if="subAccounts[uid]">
                      <v-col>
                        <router-link :to="{params: {id: uid}}">
                          {{ subAccounts[uid].first_name }} {{ subAccounts[uid].last_name }}
                        </router-link>
                        &nbsp; ({{ subAccounts[uid].email }})
                      </v-col>
                      <v-col>
                        commission earned {{ formatCurrency(managedAccount.totalCommission || 0) }}
                      </v-col>
                    </template>
                    <v-col cols="1" class="text-right" v-if="canEditCommission">
                      <v-btn icon @click="confirmRemoveManagedAccount(uid)">
                        <v-icon color="red">mdi-trash-can</v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-btn text outlined @click="fetchCustomer(id)">Reload</v-btn>
          <v-spacer/>
          <v-btn v-if="canDeleteUser" color="red" text outlined @click="deleteCustomer">Delete</v-btn>
          <v-spacer/>
          <v-btn text outlined @click="saveCustomer">Save</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import {mapActions, mapState} from 'vuex';
import CustomerAutocomplete from "@/components/CustomerAutocomplete";
import urlState from "@/router/urlState";
import {deepCopy, formatAddress, formatCurrency, formatDeliveryTimes} from "@/store/utils";
import diff from "microdiff";
import moment from "moment";

function saveFailedMessage(changesSinceLoad) {
  return `
SAVE FAILED!!!  :'(

This customer was modified since you loaded this page.  Please hit RELOAD and make your changes again.

${JSON.stringify(changesSinceLoad)}
`;
}

function dotPathToObject(customer) {
  Object.keys(customer)
    .forEach(key => {
      const valueToSet = customer[key];
      if (valueToSet !== undefined) {
        const path = key.split('.');
        if (path.length > 1) {
          let objectToSet = customer;
          while (path.length > 1) {
            const prop = path.shift();
            if (objectToSet[prop] === undefined) {
              objectToSet[prop] = {};
            }
            objectToSet = objectToSet[prop];
          }
          objectToSet[path[0]] = valueToSet;
          delete customer[key];
        }

      }
    })
}

export default {
  name: "Customer",
  components: {CustomerAutocomplete},
  mixins: [urlState],
  props: {
    id: {type: String},
    canEditSubaccounts: {
      type: Boolean,
      default: false,
      required: false
    },
    canEditPassword: {
      type: Boolean,
      default: false,
      required: false
    },
    canEditPlan: {
      type: Boolean,
      default: false,
      required: false
    },
    canEditLoginDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    canDeleteUser: {
      type: Boolean,
      default: false,
      required: false
    },
    canChangeAdmin: {
      type: Boolean,
      default: false,
      required: false
    },
    canEditCommission: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  mounted() {
    this.syncToUrl({
      param: 'tab',
      urlParam: 'tab',
      initFromRoute: true,
      parseCallback: (v) => v && Number(v)
    });
    this.syncToUrl({
      param: 'addressTab',
      urlParam: 'address',
      initFromRoute: true,
      parseCallback: (v) => v && Number(v)
    });


    return Promise.all([
      this.fetchPlans(),
      this.fetchShipping(),
      this.fetchRestrictions(),
      this.fetchCustomer(this.id),
      this.fetchMealFilters()
    ]);
    // this.fetchCities()
    //     .then(r => {
    //       console.log('cities', r);
    //     });
  },
  watch: {
    id(val) {
      this.fetchCustomer(val);
    },
    addressTab(index) {
      this.setAddress(index);
    }
  },
  data() {
    return {
      disabled: {
        // city: true,
        plan: !this.canEditPlan,
        login_disabled: !this.canEditLoginDisabled
      },
      loading: null,
      customer: {},
      customerInitialValues: {},
      address: {},
      error: null,
      subAccounts: {},
      showAddSubDialog: null,
      userToAdd: null,
      tab: null,
      useAddress: null,
      addresses: null,
      addressTab: null,
      isSecondary: null,
      availableFilters: null,
      availableFilterNames: null,
      showAddManagedAccountDialog: null
    }
  },
  methods: {
    formatCurrency,
    ...mapActions(['fetchPlans', 'fetchShipping', 'fetchRestrictions']),
    formatAddress,
    formatDeliveryTimes,
    formatAddressAndDeliveryTimes(a) {
      return `${formatAddress(a)} (${formatDeliveryTimes(a)})`;
    },
    setAddress(index) {
      if (index === 0) {
        this.isSecondary = false;
        this.address = this.customer.address;
      } else if (index > 0) {
        this.isSecondary = true;
        if (this.addresses && this.addresses.length >= index) {
          this.address = this.addresses[index - 1];
        } else {
          this.setAddress(0);
        }
      }
    },
    fetchCustomer(id) {
      this.loading = true;
      return api.get(`users/firebase/${id}`)
        .then(({data}) => {
          this.customer = data || {};
          this.customerInitialValues = deepCopy(data);
          if (!this.customer.address.delivery_instruction) {
            this.customer.address.delivery_instruction = this.customer.delivery_instruction || '';
          }
          this.address = this.customer.address || {};

          this.customer.meal_filters = data.ingredient_preferences ? (data.ingredient_preferences.nutrient_preferences || []) : [];


          this.customer.useAddress = this.customer.useAddress || {};
          this.addresses = this.customer.addresses || [];

          // add indexes to matching addresses so if they are updated we update useAddress on save
          this.addresses.forEach((a, index) => {
            if (this.customer.useAddress) {
              if (this.customer.useAddress.mon_tue_wed) {
                if (diff(this.customer.useAddress.mon_tue_wed, a).length === 0) {
                  this.customer.useAddress.mon_tue_wed.index = index;
                }
              }
              if (this.customer.useAddress.thu_fri_sat) {
                if (diff(this.customer.useAddress.thu_fri_sat, a).length === 0) {
                  this.customer.useAddress.thu_fri_sat.index = index;
                }
              }
            }
            a.index = index;
          });


          this.subAccounts = {};
          this.setAddress(this.addressTab);
          const uidsToLoad = [
            ...Object.keys((this.customer.subAccounts || {})),
            ...Object.keys((this.customer.accountsManaged || {})),
          ];

          const pendingEdits = this.customer.pendingEdits;
          this.customer['pendingEdits.plan'] = pendingEdits ? pendingEdits.plan : undefined;
          this.customer['pendingEdits.allergies'] = pendingEdits ? pendingEdits.allergies : undefined;

          return Promise
            .all(uidsToLoad
              .map(uid => api
                .get(`users/firebase/${uid}`)
                .then(({data}) => this.$set(this.subAccounts, uid, data)))
            )
          // console.log(Object.keys(data.address));
        })
        .catch(e => {
          console.warn('failed to fetch customer ' + id, e);
          this.error = 'Could not find customer.  ' + e;
          this.customer = {};
          this.address = {};
        })
        .finally(() => {
          this.loading = false;
          console.log('customer loaded', this.customer);
        });
    },
    async saveCustomer() {
      if (this.customer.allergies && this.customer.allergies.split) {
        this.customer.allergies = this.customer.allergies.split(',').map(a => a.trim());
      }
      this.customer.addresses = this.addresses;
      if (this.customer.address.delivery_instruction) {
        this.customer.delivery_instruction = this.customer.address.delivery_instruction;
      }
      console.log('saving', this.customerSaveValues)
      const latestValues = await api.get(`users/firebase/${this.id}`).then(({data}) => data);
      const changesSinceLoad = diff(latestValues, this.customerInitialValues);
      if (changesSinceLoad.length > 0) {
        alert(saveFailedMessage(changesSinceLoad));
        return;
      }
      const changesMade = diff(this.customerInitialValues, this.customerSaveValues);
      console.log('changes made', changesMade);
      const warningProps = ['allergies', 'plan', 'meal_size'];
      const isPendingChange = !!changesMade.find(c => warningProps.includes(c.path[0]));
      if (isPendingChange) {
        if (!confirm('WARNING: you should not edit the plan/allergies/meal_size directly, ' +
          'use pendingEdit.plan and pendingEdit.allergies instead or use incognito mode' +
          '\n\nDO YOU WANT TO IGNORE AND CONTINUE?')) {
          return this.fetchCustomer(this.id);
        }
      }
      return api.put(`users/firebase/${this.id}`, this.customerSaveValues)
        .then(({data}) => {
          this.customer = data || {};
          // this.address = this.customer.address;
          // console.log(Object.keys(data.address));
          // go back to list
          // this.$router.back(); // use history, so preserve search/etc
          // this.$router.push('./');  // navigate up, does not preserve search
          this.fetchCustomer(this.id);
          alert('customer changes saved!');
        })
        .catch(e => {
          alert('failed to save changes!  ' + e.message);
          console.warn('failed to save customer ' + this.id, e);
          this.error = 'Could not find customer.  ' + e;
          this.customer = {};
          this.address = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProperty(field) {
      return field.split(':')[0];
    },
    isCurrency(field) {
      return field.split(':')[1] === 'currency';
    },
    isTime(field) {
      return field.split(':')[1] === 'time';
    },
    isMultiple(field) {
      return field.split(':')[1] === 'options-multiple';
    },
    isCheckbox(field) {
      return field.split(':')[1] === 'boolean';
    },
    getText(field) {
      return field.split(':')[3] || 'text';
    },
    getValue(field) {
      return field.split(':')[4] || 'value';
    },
    isSelect(field) {
      const splitElement = (field.split(':')[1] || '').split('-')[0];
      return splitElement === 'options';
    },
    selectOptions(field) {
      let vals = field.split(':').slice(2);
      vals = vals.join(':');
      const prop = vals.match(/this\./);
      if (prop) {
        return this[vals.replace('this.', '')];
      }
      return vals.split(',');
    },
    isTextField(field) {
      return field.split(':').length === 1;
    },
    changeEmailPrompt() {
      const email = prompt('enter the new email for this customer');
      this.loading = true;
      if (email) {
        api.post('v2/user/update-email', {
          email,
          uid: this.customer.uid
        })
          .then(r => {
            console.log('result', r);
            const {
              ok,
              error
            } = r.data;
            if (ok) {
              alert('succeeded');
              return this.fetchCustomer(this.customer.uid);
            } else {
              alert('failed to change: ' + error)
            }
          })
          .catch((e) => alert('failed to change: ' + e))
          .finally(() => this.loading = false);
      }
    },
    changePasswordPrompt() {
      const password = prompt('enter the password you would like to set');
      if (password) {
        api.post('v2/user/update-password', {
          email: this.customer.email,
          uid: this.customer.uid,
          password
        })
          .then(r => {
            console.log('result', r);
            const {
              ok,
              error
            } = r.data;
            if (ok) {
              alert('succeeded');
            } else {
              alert('failed to change: ' + error);
            }
          })
          .catch((e) => alert('failed to change: ' + e));
      }
    },
    confirmRemoveManagedAccount(uid) {
      if (confirm('Are you sure you want to remove this managed account?')) {
        return this.updateManagedAccount(uid, false);
      }
    },
    confirmUnlinkSub(uid) {
      if (confirm('Are you sure you want to disconnect this sub account?  Note, you will need to ensure the sub account has a payment method after disconnecting.')) {
        delete this.customer.subAccounts[uid];
        delete this.subAccounts[uid];
        this.$set(this.customer, 'subAccounts', this.customer.subAccounts);
        return this.saveCustomer();
      }
    },
    deleteCustomer() {
      if (confirm('Are you sure you want to delete this customer?  No going back!')) {
        return api.delete(`v2/user/${this.id}`)
          .then(({data}) => {
            this.customer = data;
          });
      }
    },
    async updateManagedAccount(uid, allowed) {
      const result = await api.post('v2/customer/update-managed-account', {
        managerUid: this.id,
        uid,
        allowed
      });
      console.log('result', result);
      return this.fetchCustomer(this.id)
    },
    async addSubAccount(user) {
      if (user.subAccounts) {
        alert(`the user ${user.email} has subaccounts - they must be removed before you can make ${user.email} a subaccount`);
        return;
      }
      this.loading = true;
      const {uid} = user;
      this.customer.subAccounts = this.customer.subAccounts || {};
      this.$set(this.customer.subAccounts, uid, {
        uid,
        timestamp: Date.now()
      });
      this.subAccounts[uid] = user;
      this.loading = false;
      await this.saveCustomer();
      this.showAddSubDialog = false;
    },
    addNewAddress() {
      this.addresses.push({line1: 'new'});
    },
    addNote() {
      if (!this.customer.customer_notes) {
        this.$set(this.customer, 'customer_notes', []);
      }
      this.customer.customer_notes.unshift({})
    },
    async fetchMealFilters() {
      await api.get('/v2/meal-filter')
        .then(({data}) => {
          this.availableFilters = data;
          this.availableFilterNames = data.map(d => d.id);
        });
    },
  },
  computed: {
    ...mapState(['plans', 'shipping']),
    restrictions() {
      const map = {};
      Object.values(this.$store.state.restrictions || {})
        .forEach(({
                    id,
                    text
                  }) => id ? map[id] = text : '');
      return Object.keys(map)
        .map(k => ({
          value: k,
          text: map[k]
        }))
    },
    addressList() {
      return this.addresses ? this.addresses.map((a) => (`[${a.index + 1}]`) + formatAddress(a, {short: true})) : [];
    },
    addressFields() {
      const cities = Object.keys(this.shipping);
      return [
        "unit", "line1", "line2",
        'blank',
        `city`,
        `city:options:${cities.join(',')}`,
        'state:options:BC,ON',
        "postal_code",
        "location:options:home,office",
        "earliest_time:time",
        "latest_time:time",
        // "delivery_instruction",
        'use_shipping:boolean'
        // "country", "state"
      ]
    },

    fields() {
      return [
        "first_name",
        "last_name",
        `plan:options:${Object.keys(this.plans).join(',')}`,
        ...(this.canEditPlan ? [`pendingEdits.plan:options:${Object.keys(this.plans).join(',')}`] : []),
        "meal_size:options:small,medium,large",
        "phone_number",
        // "delivery_instruction",
        `allergies:options-multiple:this.restrictions`,
        `pendingEdits.allergies:options-multiple:this.restrictions`,
        `meal_filters:options-multiple:this.availableFilterNames`,
        "special_request",
        "tags:options-multiple:DoNotPrint",
        "comment",
        "price_adjustment:currency",
        "price_adjustment_label",
        "free_shipping:boolean",
        "meal_at_door:boolean",
        // "newsletter:boolean",
        "show_meals:boolean",
        // "is_active:boolean",
        "login_disabled:boolean",
        "last_bin_check:boolean",
        "newsletter:boolean",
        "weeklyReminders:boolean",
        // "failed_payment_excempt:boolean",
        ...(this.canChangeAdmin
            ? [
              "disable_auto_charge:boolean",
              "is_admin:boolean",
              "is_subaccount_admin:boolean"
            ]
            : []
        ),
        ...(this.canEditCommission
            ? [
              "is_account_manager:boolean",
              "commission_percent:options:3,6"
            ]
            : []
        )
      ];
    },
    upcomingDeliveryDates() {
      const dates = [];
      const now = moment();
      const date = moment();
      let count = 4;
      while (count-- > 0) {
        date.startOf('week');
        if (date.isSameOrAfter(now, 'day')) {
          dates.push(date.format(moment.HTML5_FMT.DATE));
        }
        date.add(3, 'days');
        if (date.isSameOrAfter(now, 'day')) {
          dates.push(date.format(moment.HTML5_FMT.DATE));
        }
        date.add(1, 'week');
      }
      return dates;
    },
    customerSaveValues() {
      const customer = {...this.customer};
      if (customer.meal_filters) {
        customer.ingredient_preferences = customer.ingredient_preferences || {};
        customer.ingredient_preferences.nutrient_preferences = this.customer.meal_filters;
        delete customer.meal_filters;
      }

      if (customer.useAddress) {
        if (customer.useAddress.mon_tue_wed) {
          const index = customer.addresses.findIndex(a => a.index === customer.useAddress.mon_tue_wed.index);
          if (index === -1) {
            alert('could not set mon_tue_wed delivery overrride');
          } else {
            customer.useAddress.mon_tue_wed = this.addresses[index];
          }
        }
        if (customer.useAddress.thu_fri_sat) {
          const index = customer.addresses.findIndex(a => a.index === customer.useAddress.thu_fri_sat.index);
          if (index === -1) {
            alert('could not set thu_fri_sat delivery overrride');
          } else {
            customer.useAddress.thu_fri_sat = this.addresses[index];
          }
        }
      }

      // replaces things like A.B.C = 1 with A : { B : { C : 1 } } }
      dotPathToObject(customer);
      return customer;
    }
  },
}
</script>

<style scoped>

</style>
